
import React from "react"

import PackageeditorPage from "../../components/negosyonow/controls/packageeditor";

const PackagereleasePage = ({location}) => {

	return <PackageeditorPage
				location={location}
				mode={1} // 1-Release, 2-Receive, 3-Full
				/>

}

export default PackagereleasePage;
